/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewUserCreate extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        this.innerHTML = Mustache.render(template, {});
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-create");
        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-UserCreate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                email:this.querySelector("#input-email").value,                
                iban:this.querySelector("#input-iban").value,                
                totalAnswered:parseFloat(this.querySelector("#input-totalAnswered").value),                
                totalGain:parseFloat(this.querySelector("#input-totalGain").value),                         
                role:this.querySelector("#input-role").options[this.querySelector("#input-role").selectedIndex].value,

            }

        })

        window.location.assign(Global.appUrl + "#/admin/user/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-user-create', ViewUserCreate);