/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";
import firebase from "firebase/app";
import { Loader } from "@googlemaps/js-api-loader"

import html from 'bundle-text:./template.html';

export class ViewLocationCreate extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {

        var getSurveys =  Global.firebaseFunctions.httpsCallable('admin-SurveyList');
        let result = await getSurveys({  })

        this.innerHTML = Mustache.render(template, {surveys:result.data});
        this.querySelector("#input-qrcodeId").value = this.generateUID ();
        this.querySelector("#span-qrcodeUrl").innerHTML = "https://app.satisfyy.fr/#/qrcode/" + this.querySelector("#input-qrcodeId").value;

        this.initMap ();
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-create");
        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });
    }

    initMap() {

        const loader = new Loader({
            apiKey: "AIzaSyAlbv9mVARluaoyJr_ZOdmP-e606mPq6fI",
            version: "weekly",
          });
          loader.load().then(() => {
        const myLatlng = { lat: 48.84949852419321, lng: 2.34207089264451 };
        const map = new google.maps.Map(this.querySelector("#map"), {
          zoom: 10,
          center: myLatlng,
        });
        let infoWindow = new google.maps.InfoWindow({
          content: "Cliquer pour enregistrer la position.",
          position: myLatlng,
        });
      
        infoWindow.open(map);
        map.addListener("click", (mapsMouseEvent) => {
            infoWindow.close();
            console.log (mapsMouseEvent.latLng);
            this.querySelector("#input-positionLat").value = mapsMouseEvent.latLng.lat();
            this.querySelector("#input-positionLng").value = mapsMouseEvent.latLng.lng();

        });
    });
      }
      

    async saveData () {
        Global.showLoading ();

        var saveData =  Global.firebaseFunctions.httpsCallable('admin-LocationCreate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                signupId:this.querySelector("#input-signupId").value,
                qrcodeId:this.querySelector("#input-qrcodeId").value,
                address:this.querySelector("#input-address").value,
                surveys:this.querySelector("#input-surveys").options[this.querySelector("#input-surveys").selectedIndex].value,
                name:this.querySelector("#input-name").value,
                companyName:this.querySelector("#input-companyName").value,
                ownerName:this.querySelector("#input-ownerName").value,
                paymentType:this.querySelector("#input-paymentType").options[this.querySelector("#input-paymentType").selectedIndex].value,
                iban:this.querySelector("#input-iban").value,
                positionLat:parseFloat(this.querySelector("#input-positionLat").value),
                positionLng:parseFloat(this.querySelector("#input-positionLng").value)

            }
            
        
        })

        window.location.assign(Global.appUrl + "#/admin/location/list");
        
    }

    generateUID() {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    }

    disconnectedCallback() {

    }

}

customElements.define('view-location-create', ViewLocationCreate);