/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";
import firebase from "firebase/app";

import html from 'bundle-text:./template.html';

export class ViewLocationEdit extends HTMLElement {

    constructor(options) {
        super();
        this.dataId = options.id;

    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getData =  Global.firebaseFunctions.httpsCallable('admin-LocationGet');
        let result = await getData({ id : this.dataId });
        var getSurveys =  Global.firebaseFunctions.httpsCallable('admin-SurveyList');
        let surveys = await getSurveys({  });
        this.innerHTML = Mustache.render(template, {location: result.data, surveys:surveys.data});
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-save");
        this.buttonDelete = this.querySelector("#button-delete");

        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });

        this.buttonDelete.addEventListener('click', event => {
            this.deleteData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-LocationUpdate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                signupId:this.querySelector("#input-signupId").value,
                qrcodeId:this.querySelector("#input-qrcodeId").value,
                address:this.querySelector("#input-address").value,
                surveys:this.querySelector("#input-surveys").value,
                name:this.querySelector("#input-name").value,
                companyName:this.querySelector("#input-companyName").value,
                ownerName:this.querySelector("#input-ownerName").value,
                paymentType:this.querySelector("#input-paymentType").options[this.querySelector("#input-paymentType").selectedIndex].value,
                iban:this.querySelector("#input-iban").value,
                positionLat:parseFloat(this.querySelector("#input-positionLat").value),
                positionLng:parseFloat(this.querySelector("#input-positionLng").value)
            }

        })

        window.location.assign(Global.appUrl + "#/admin/location/list");
        
    }

    async deleteData () {
        Global.showLoading ();
        var deleteData =  Global.firebaseFunctions.httpsCallable('admin-LocationDelete');
        let result = await deleteData({ 
            id : this.dataId
        })

        window.location.assign(Global.appUrl + "#/admin/location/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-location-edit', ViewLocationEdit);