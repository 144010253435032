/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewPaymentEdit extends HTMLElement {

    constructor(options) {
        super();
        this.dataId = options.id;

    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getData =  Global.firebaseFunctions.httpsCallable('admin-PaymentGet');
        let result = await getData({ id : this.dataId })
        this.innerHTML = Mustache.render(template, result.data);
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-save");
        this.buttonDelete = this.querySelector("#button-delete");

        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });

        this.buttonDelete.addEventListener('click', event => {
            this.deleteData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-PaymentUpdate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                id : this.querySelector("#input-id").value,
                userId:this.querySelector("#input-userId").value,                
                locationId:this.querySelector("#input-locationId").value, 
                surveyId:this.querySelector("#input-surveyId").value, 
                answerId:this.querySelector("#input-answerId").value, 
                status:this.querySelector("#input-status").value, 
                amount:parseFloat(this.querySelector("#input-amount").value), 
                iban:this.querySelector("#input-iban").value, 
                datePaid:this.querySelector("#input-datePaid").value, 
                dateCreated:this.querySelector("#input-dateCreated").value, 
                paid:this.querySelector("#input-paid").value
            }

        })

        window.location.assign(Global.appUrl + "#/admin/payment/list");
        
    }

    async deleteData () {
        Global.showLoading ();
        var deleteData =  Global.firebaseFunctions.httpsCallable('admin-PaymentDelete');
        let result = await deleteData({ 
            id : this.dataId
        })

        window.location.assign(Global.appUrl + "#/admin/payment/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-payment-edit', ViewPaymentEdit);