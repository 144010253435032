/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";

import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

import html from 'bundle-text:./template.html';
import { Collection } from "../../models/collection.js";

export class ViewQrcode extends HTMLElement {

    constructor(options) {
        super();
        this.qrcodeId = options.qrcodeId;
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {

        var getSurveys =  Global.firebaseFunctions.httpsCallable('getSurveys');
        var result = await getSurveys({ qrcodeId: this.qrcodeId });

        if (result.data.surveys.length > 0) {
            this.surveyId = result.data.surveys[0].id;
            this.formId = result.data.surveys[0].formId;
            this.locationId = result.data.locationId;
            console.log (this.locationId);
        }
            else {
                
                swal("Oups !","Aucun questionnaire actif ici !", "error");
                window.location.assign(Global.appUrl + "#/");
                return;
        }

        this.innerHTML = Mustache.render(template, {});
        this.checkLocation();
        this.introVideo = this.querySelector ('#intro-video');
        this.introVideo.src =  result.data.surveys[0].introVideo;
        this.introVideo.load ();
        Global.hideLoading ();

        this.panelTypeform =  this.querySelector('#panel-typeform');
        this.panelWelcome =  this.querySelector('#panel-welcome');
        this.panelEnd =  this.querySelector('#panel-end');
        this.buttonStartVideo = this.querySelector('#button-start-video');
        this.buttonStartSurvey = this.querySelector('#button-start-survey');

        this.buttonStartVideo .addEventListener('click', event => {
            this.startVideo();
        });

        this.buttonStartSurvey.addEventListener('click', event => {
            this.startSurvey();
        });

        this.introVideo.addEventListener('timeupdate', (event) => {

            if (this.introVideo.currentTime > this.introVideo.duration - 10) {
                this.buttonStartSurvey.style.display = "inline-flex";
            }

        });

    }

    startVideo () {
        this.buttonStartVideo.style.display = "none";
        this.introVideo.play ();
    }

    startSurvey () {
        this.panelWelcome.style.display = "none";
        this.introVideo.pause ();
        this.panelTypeform.style.display = "block";
        this.showTypeForm (this.formId);
    }

    async endSurvey (formId,responseId) {
       this.panelTypeform.style.display = "none";

       if ( Global.user ) {
            var endSurvey =  Global.firebaseFunctions.httpsCallable('endSurvey');
            console.log ({ locationId:this.locationId, surveyId: this.surveyId,responseId:responseId, formId:this.formId, userId: Global.user.uid });
            var result = await endSurvey({ locationId:this.locationId, surveyId: this.surveyId,responseId:responseId, formId:this.formId, userId: Global.user.uid });
       } else {
            Global.pendingSurvey = { locationId:this.locationId,surveyId: this.surveyId,responseId:responseId, formId:this.formId, userId: "none" };
             swal("Merci !","Vous devez avoir un compte pour recevoir votre gain", "info").then(() => {
                window.location.assign(Global.appUrl + "#/signup");

              });

            
       }
       this.panelEnd.style.display = "block";

    }

    checkLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getPosition, this.positionError);
        } else {
            swal("Oups !","Vous devez activer la géolocalisation.", "error")
        }
    }
      
    getPosition(position) {
        Global.userPosition = position;
        console.log (position.coords.latitude + '/' + position.coords.longitude);
    }

    positionError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            swal("Oups !","Vous devez activer la géolocalisation.", "error")
            break;
          case error.POSITION_UNAVAILABLE:
            swal("Oups !","Vous devez activer la géolocalisation.", "error")
            break;
          case error.TIMEOUT:
            swal("Oups !","Vous devez activer la géolocalisation.", "error")
            break;
          case error.UNKNOWN_ERROR:
            swal("Oups !","Vous devez activer la géolocalisation.", "error")
            break;
        }
      }

    showTypeForm (typeformId) {
        this.panelTypeform.style.display = "block"
        createWidget(typeformId, {
            container:this.panelTypeform,
            onSubmit: ({ formId, responseId }) => {
                this.endSurvey (formId,responseId);
              },        
        })

    }

    disconnectedCallback() {

    }

}

customElements.define('view-qrcode', ViewQrcode);