/**
 * View Signup
 *
 * @description :: View used to create a new user
 */
import Mustache from "mustache";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewSignup extends HTMLElement {

    constructor(options) {
        super();
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;

        this.appendChild(styleSheet);

    }

    connectedCallback() {
        this.initView(html);
    }

    initView(template) {
        this.innerHTML = Mustache.render(template, {});
        
        Global.hideLoading ();

        this.buttonCreate = this.querySelector("#button-create-account");
        this.inputEmail = this.querySelector("#input-email");
        this.inputPassword = this.querySelector("#input-password");
        this.textHelp = this.querySelector("#text-help");
        
   
        this.querySelector('#button-cgu').addEventListener('click', event => {
            this.querySelector("#modal-cgu").style.display ="block";
        });

        this.querySelector('#button-close-cgu').addEventListener('click', event => {
            this.querySelector("#modal-cgu").style.display ="none";
        });

        this.buttonCreate.addEventListener('click', event => {

            this.createAccount();
        });

    }

    async createAccount () {

        if (!this.querySelector('#check-cgu').checked) {
            swal("Oups !","Vous devez accépter les CGU pour continuer.", "error");
            return;
        }
        Global.showLoading ();

        var email = this.inputEmail.value;
        var password = this.inputPassword.value;
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
                // Signed in 
                var user = userCredential.user;
                let age = firebase.firestore.Timestamp.fromMillis (this.querySelector('#input-age').valueAsNumber);
                let sex = this.querySelector("#input-sex").options[this.querySelector("#input-sex").selectedIndex].value;

                var userCreate =  Global.firebaseFunctions.httpsCallable('userCreate');
                let result = await userCreate({age:age, sex:sex, id : user.uid,
                    email: user.email,
                    createdAt: firebase.firestore.Timestamp.now (),
                    totalGain:0,
                    totalAnswered:0});     

                window.location.assign(Global.appUrl+"#");
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                swal("Oups !",errorMessage, "error");

               // this.textHelp.innerHTML = errorMessage;
            });
    }


    disconnectedCallback() {

    }

}

customElements.define('view-signup', ViewSignup);