/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';
import { Collection } from "../../models/collection.js";

export class ViewGains extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {

        var getGains =  Global.firebaseFunctions.httpsCallable('getGains');
        let result = await getGains({  })
        this.innerHTML = Mustache.render(template, {user : Global.user, gains : result.data.gains});
        Global.hideLoading ();

    }


    disconnectedCallback() {

    }

}

customElements.define('view-gains', ViewGains);