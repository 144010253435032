/**
 * boot.js
 *
 * @description :: Web app entry point
 */



import swal from 'sweetalert';

import "../css/main.css";
import "../node_modules/bulma/css/bulma.min.css";
import "../node_modules/firebaseui/dist/firebaseui.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";
import { getFirestore } from "firebase/firestore";

import { getApp } from "@firebase/app";

import Navigo from "navigo";

// User views

import { ViewMenu } from './views/menu/controller.js';
import { ViewSignup } from './views/signup/controller.js';
import { ViewCallback } from './views/callback/controller.js';

import { ViewLogin } from './views/login/controller.js';
import { ViewDefault } from './views/default/controller.js';
import { ViewQrcode } from './views/qrcode/controller.js';
import { ViewAccount } from './views/account/controller.js';
import { ViewGains } from './views/gains/controller.js';
import { ViewAdminDefault } from './views/admin/default/controller';

// Pro views

import { ViewProEdit} from './views/pro/edit/controller';
import { ViewProDashboard } from './views/pro/dashboard/controller';
import { ViewProSignup } from './views/pro/signup/controller';


// Admin Survey
import { ViewSurveyEdit } from './views/admin/survey/edit/controller.js';
import { ViewSurveyCreate } from './views/admin/survey/create/controller.js';
import { ViewSurveyList } from './views/admin/survey/list/controller.js';
// Admin Location
import { ViewLocationEdit } from './views/admin/location/edit/controller.js';
import { ViewLocationCreate } from './views/admin/location/create/controller.js';
import { ViewLocationList } from './views/admin/location/list/controller.js';
// Admin Payments
import { ViewPaymentEdit } from './views/admin/payment/edit/controller.js';
import { ViewPaymentCreate } from './views/admin/payment/create/controller.js';
import { ViewPaymentList } from './views/admin/payment/list/controller.js';
// Admin User
import { ViewUserEdit } from './views/admin/user/edit/controller.js';
import { ViewUserCreate } from './views/admin/user/create/controller.js';
import { ViewUserList } from './views/admin/user/list/controller.js';

import AppDatastore from './components/app-datastore.js';
import ApiConnector from './components/api-connector.js';

import { globalEval } from "jquery";


Global.router = new Navigo('/', { hash: true });

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(new URL('../service-worker.js', import.meta.url))
        .then(function (registration) {

        })
        .catch(function (error) {
            console.log('Service worker registration failed, error:', error);
        });
}



if (window.location.hostname != 'localhost') {

    Global.appData = new AppDatastore({
        firebaseConfig: {
            apiKey: "AIzaSyBT9_x00F7gndZ3FdP1jfJUZVHuQSuRiEE",
            authDomain: "satisfyy-25cda.firebaseapp.com",
            projectId: "satisfyy-25cda",
            storageBucket: "satisfyy-25cda.appspot.com",
            messagingSenderId: "109930273584",
            appId: "1:109930273584:web:9a7292bb0dc7ae5d74c6f5",
            measurementId: "G-KJY4NFCKGE"
        }
    }, false);

    Global.firebaseAuth = firebase.auth();
    Global.appUrl = "https://app.satisfyy.fr/";
    Global.firebaseFunctions = firebase.functions();
    Global.functionUrl = "https://us-central1-satisfyy-25cda.cloudfunctions.net/";
    Global.firebaseApp = Global.appData.app;

} else {
    // DEV CONFIG
    
    Global.appData = new AppDatastore({
        firebaseConfig: {
            apiKey: "AIzaSyBT9_x00F7gndZ3FdP1jfJUZVHuQSuRiEE",
            authDomain: "satisfyy-25cda.firebaseapp.com",
            projectId: "satisfyy-25cda",
            storageBucket: "satisfyy-25cda.appspot.com",
            messagingSenderId: "109930273584",
            appId: "1:109930273584:web:9a7292bb0dc7ae5d74c6f5",
            measurementId: "G-KJY4NFCKGE"
        }
    }, false);
    Global.appUrl = "https://localhost:1234/";
    Global.firebaseFunctions = firebase.functions();
    Global.firebaseAuth = firebase.auth();
    Global.firebaseAuth.useEmulator("http://localhost:9099");
    Global.firebaseFunctions.useEmulator("localhost", 5001);
    var db = firebase.firestore();
    if (location.hostname === "localhost") {
      db.useEmulator("localhost", 8080);
    }
    
    Global.functionUrl = "https://http://127.0.0.1:5001/satisfyy-25cda/us-central1/";
    Global.firebaseApp = Global.appData.app;

}

Global.apiConnector = new ApiConnector ();
Global.firebaseAuth.languageCode = 'fr';

navigator.serviceWorker.onmessage = (event) => {

};

const mainDiv = document.querySelector("#main-div");
const viewMenu = document.querySelector("view-menu");

var currentView = null;
Global.user = null;
Global.isFirstLoad = true;

firebase.auth().onAuthStateChanged(async function (user) {
    if (user) {

        Global.user = user;
        console.log (Global.pendingSurvey);
        
        if ( Global.pendingSurvey ) {
            Global.pendingSurvey.userId = user.uid;
            var endSurvey =  Global.firebaseFunctions.httpsCallable('endSurvey');
            var result = await endSurvey(Global.pendingSurvey);
            swal("Merci !","Votre questionnaire a bien été enregistré !", "success");
            Global.pendingSurvey = null;
        }

        var userGet =  Global.firebaseFunctions.httpsCallable('userGet');
        let userData = await userGet();
        if (userData.data) {
            if ((userData.data.iban == null) || (userData.data.iban == "none")) {
                swal({
                    title: "Votre compte",
                    text: "Pour recevoir vos gains, pensez à compléter vos informations bancaires.",
                    icon: "info",
                    buttons: ["Plus tard", "Compléter"],             
                })
                .then((confirm) => {
                    if (confirm) {
                        window.location.assign(Global.appUrl + "#/account");
                    }
                });
            }
        }

        viewMenu.updateView ();


    } else {
        Global.user = null;
        viewMenu.updateView ();

    }
});

Global.getCurrentUser = function (auth) {
    return new Promise((resolve, reject) => {
       const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
       }, reject);
    });
}

Global.getCurrentUserRoles = async function () {
    
    let idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
    return idTokenResult.claims;

}

Global.showLoading = function () {
    document.querySelector('#loader').style.display = "flex";
}

Global.hideLoading = function () {
    document.querySelector('#loader').style.display = "none";
}


Global.changeView = async function (newView, option, needsLogin, isAdmin) {
    Global.showLoading ();
    const user =  await Global.getCurrentUser (firebase.auth());
    Global.user = user;
    if (user) {
        Global.user.claims = await Global.getCurrentUserRoles ();
    }

    if (Global.isFirstLoad) {

        Global.isFirstLoad = false;
    }
    
    if (Global.currentView) {
        mainDiv.removeChild(Global.currentView);
    }

    if (needsLogin) {
        if (user != null) {
            Global.currentView = new newView(option);
            mainDiv.appendChild(Global.currentView);
        } else {
            Global.currentView = new ViewLogin({requestedUrl : Global.appUrl});
            mainDiv.appendChild(Global.currentView);
        }
    } else {
        Global.currentView = new newView(option);
        mainDiv.appendChild(Global.currentView);
    }

}

Global.router.on("/", (match) => {
        Global.changeView(ViewDefault, {}, false);
    })
    .on("/login", (match) => {
        Global.changeView(ViewLogin, {requestedUrl : Global.appUrl + "#"}, false);
    })
    .on("/logout", (match) => {
        firebase.auth().signOut().then(() => {
            Global.changeView(ViewDefault, {}, false);
        }).catch((error) => {
            Global.changeView(ViewDefault, {}, false);
        });
    })      
    .on("/signup", (match) => {
        Global.changeView(ViewSignup, {}, false);
    }).on("/callback", (match) => {
        Global.changeView(ViewCallback, {}, false);
    }).on("/gains", (match) => {
        Global.changeView(ViewGains, {}, true);
    }).on("/account", (match) => {
        Global.changeView(ViewAccount, {}, true);
    }).on(/qrcode\/(.*)/, (match) => {
        Global.changeView(ViewQrcode, {qrcodeId : match.data[0]}, false);
    }).on("/pro", (match) => {
        Global.changeView(ViewProDashboard, {}, true);
    }).on("/pro/signup", (match) => {
        Global.changeView(ViewProSignup, {}, false);
    }).on("/pro/edit", (match) => {
        Global.changeView(ViewProEdit, {}, true);
    }).on("/admin", (match) => {
        Global.changeView(ViewAdminDefault, {}, false);
    })
    // Admin survey
    .on(/admin\/survey\/edit\/(.*)/, (match) => {
        Global.changeView(ViewSurveyEdit, {id : match.data[0]}, false, true);
    }).on("/admin/survey/list", (match) => {
        Global.changeView(ViewSurveyList, {}, false, true);
    }).on("/admin/survey/create", (match) => {
        Global.changeView(ViewSurveyCreate, {}, false, true);
    })
    // Admin location
    .on(/admin\/location\/edit\/(.*)/, (match) => {
        Global.changeView(ViewLocationEdit, {id : match.data[0]}, false, true);
    }).on("/admin/location/list", (match) => {
        Global.changeView(ViewLocationList, {}, false, true);
    }).on("/admin/location/create", (match) => {
        Global.changeView(ViewLocationCreate, {}, false, true);
    })
    // Admin payment
    .on(/admin\/payment\/edit\/(.*)/, (match) => {
        Global.changeView(ViewPaymentEdit, {id : match.data[0]}, false, true);
    }).on("/admin/payment/list", (match) => {
        Global.changeView(ViewPaymentList, {}, false, true);
    }).on("/admin/payment/create", (match) => {
        Global.changeView(ViewPaymentCreate, {}, false, true);
    })
    // Admin user
    .on(/admin\/user\/edit\/(.*)/, (match) => {
        Global.changeView(ViewUserEdit, {id : match.data[0]}, false, true);
    }).on("/admin/user/list", (match) => {
        Global.changeView(ViewUserList, {}, false, true);
    }).on("/admin/user/create", (match) => {
        Global.changeView(ViewUserCreate, {}, false, true);
    })
    .resolve();

Global.debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
