/**
 * APIConnector class
 *
 * @description :: Class used to connect and send data to  API
 */

class APIConnector {


}

export default APIConnector;