/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewProEdit extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getData =  Global.firebaseFunctions.httpsCallable('pro-LocationGet');
        let result = await getData({ });
        console.log (result);
        this.dataId = result.dataId;
        this.innerHTML = Mustache.render(template, result.data.data);
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-save");
        
        this.buttonSave.addEventListener('click', event => {
            Global.showLoading ();

            this.saveData();
        });

    }

    async saveData () {

        var saveData =  Global.firebaseFunctions.httpsCallable('pro-LocationUpdate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                address:this.querySelector("#input-address").value,
                name:this.querySelector("#input-name").value,
                companyName:this.querySelector("#input-companyName").value,
                ownerName:this.querySelector("#input-ownerName").value,
                paymentType:this.querySelector("#input-paymentType").options[this.querySelector("#input-paymentType").selectedIndex].value,
                iban:this.querySelector("#input-iban").value
            }

        })

        window.location.assign(Global.appUrl + "#/pro");
        
    }

    disconnectedCallback() {

    }

}

customElements.define('view-pro-edit', ViewProEdit);