/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewSurveyList extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getSurveys =  Global.firebaseFunctions.httpsCallable('admin-SurveyList');
        let result = await getSurveys({  })
       
        this.innerHTML = Mustache.render(template, {user : Global.user, data : result});
        Global.hideLoading ();

    }


    disconnectedCallback() {

    }

}

customElements.define('view-survey-list', ViewSurveyList);