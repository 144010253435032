/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewUserEdit extends HTMLElement {

    constructor(options) {
        super();
        this.dataId = options.id;

    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getData =  Global.firebaseFunctions.httpsCallable('admin-UserGet');
        let result = await getData({ id : this.dataId })
        console.log (result.data);
        this.innerHTML = Mustache.render(template, result.data);
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-save");
        this.buttonDelete = this.querySelector("#button-delete");
        this.buttonPro = this.querySelector("#button-pro");

        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });

        this.buttonDelete.addEventListener('click', event => {
            this.deleteData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-UserUpdate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                email:this.querySelector("#input-email").value,                
                iban:this.querySelector("#input-iban").value,                
                totalAnswered:parseFloat(this.querySelector("#input-totalAnswered").value),                
                totalGain:parseFloat(this.querySelector("#input-totalGain").value),                
                role:this.querySelector("#input-role").options[this.querySelector("#input-role").selectedIndex].value,
            }

        })

        window.location.assign(Global.appUrl + "#/admin/user/list");
        
    }

    async deleteData () {
        Global.showLoading ();
        var deleteData =  Global.firebaseFunctions.httpsCallable('admin-UserDelete');
        let result = await deleteData({ 
            id : this.dataId
        })

        window.location.assign(Global.appUrl + "#/admin/user/list");
        
    }

    disconnectedCallback() {

    }

}

customElements.define('view-user-edit', ViewUserEdit);