/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';
import { Collection } from "../../models/collection.js";

export class ViewAccount extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {

        var userGet =  Global.firebaseFunctions.httpsCallable('userGet');
        let result = await userGet();
        this.innerHTML = Mustache.render(template, {user:result.data});
        Global.hideLoading ();
        this.buttonConnect = this.querySelector("#button-connect");
        this.buttonAddIban = this.querySelector("#button-add-iban");

        this.buttonConnect.addEventListener('click', event => {
            Global.showLoading ();
            this.connectAccount();
        });

        this.buttonAddIban.addEventListener('click', event => {
            Global.showLoading ();
            this.addIban();
        });
    }

    async addIban () {
        let iban = this.querySelector('#input-iban').value;
        var userUpdate =  Global.firebaseFunctions.httpsCallable('userUpdate');
        let result = await userUpdate({data:{ iban:iban}});
        Global.hideLoading ();

        swal ("Merci !", "Votre IBAN a bien été ajouté","success");
    }

    async connectAccount () {
        var connectBridgeItem =  Global.firebaseFunctions.httpsCallable('connectBridgeItem');
        let result = await connectBridgeItem({ callback_url:Global.appUrl + "#/callback"});
        Global.hideLoading ();

        window.location.assign(result.data.redirect_url);
    }


    disconnectedCallback() {

    }

}

customElements.define('view-account', ViewAccount);