/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewProDashboard extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {

        var getDashboard =  Global.firebaseFunctions.httpsCallable('pro-GetDashboard');
        let result = await getDashboard({  })
       
        this.innerHTML = Mustache.render(template, {user : Global.user, data : result.data});
        Global.hideLoading ();

    }


    disconnectedCallback() {

    }

}

customElements.define('view-pro-dashboard', ViewProDashboard);