/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';
import { Collection } from "../../models/collection.js";

export class ViewDefault extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        if (Global.user) {
            var userGet =  Global.firebaseFunctions.httpsCallable('userGet');
            let userData = await userGet();
            this.innerHTML = Mustache.render(template, {user : Global.user, userData:userData.data});
        } else {
            this.innerHTML = Mustache.render(template, {user : Global.user, userData:null});
        }
        
        Global.hideLoading ();

    }


    disconnectedCallback() {

    }

}

customElements.define('view-default', ViewDefault);