/**
 * View Signup
 *
 * @description :: View used to create a new user
 */
import Mustache from "mustache";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewProSignup extends HTMLElement {

    constructor(options) {
        super();
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;

        this.appendChild(styleSheet);

    }

    connectedCallback() {
        this.initView(html);
    }

    initView(template) {
        this.innerHTML = Mustache.render(template, {});
        
        Global.hideLoading ();

        this.buttonCreate = this.querySelector("#button-create-account");
        this.inputEmail = this.querySelector("#input-email");
        this.inputPassword = this.querySelector("#input-password");
        this.inputSignupId = this.querySelector("#input-signupid");
        this.textHelp = this.querySelector("#text-help");
        
        this.querySelector('#button-cgu').addEventListener('click', event => {
            this.querySelector("#modal-cgu").style.display ="block";
        });

        this.querySelector('#button-close-cgu').addEventListener('click', event => {
            this.querySelector("#modal-cgu").style.display ="none";
        });

        this.buttonCreate.addEventListener('click', event => {

            this.createAccount();
        });

    }

    async createAccount () {

        if (!this.querySelector('#check-cgu').checked) {
            swal("Oups !","Vous devez accépter les CGU pour continuer.", "error");
            return;
        }

        Global.showLoading ();

        var userCreate =  Global.firebaseFunctions.httpsCallable('pro-UserCreate');
        let result = await userCreate({
                    email: this.inputEmail.value,
                    password: this.inputPassword.value,
                    signupId: this.inputSignupId.value
                    
                });
        
        Global.hideLoading ();

        console.log (result.data);

        if (result.data.status == "success") {
            swal ("Merci !", "Votre inscription est bien enregistrée, veuillez vous identifier désormais.", "info");
            window.location.assign(Global.appUrl+"#/login");


        } else {
            swal ("Merci !", result.data.message, "error");
        }


          
    }


    disconnectedCallback() {

    }

}

customElements.define('view-pro-signup', ViewProSignup);