/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewPaymentList extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getPayments =  Global.firebaseFunctions.httpsCallable('admin-PaymentList');
        let result = await getPayments({  })
       
        this.innerHTML = Mustache.render(template, {user : Global.user, data : result});
        this.buttonConnect = this.querySelector("#button-connect");

        this.buttonConnect.addEventListener('click', event => {
            Global.showLoading ();
            this.connectBank();
        });

        this.buttonPay = this.querySelector("#button-pay");
        this.buttonSync = this.querySelector("#button-sync");
        this.buttonDelete = this.querySelector("#button-delete");

        this.buttonDelete.addEventListener('click', event => {
            Global.showLoading ();
            this.deleteBridgeUsers();
        });


        this.buttonPay.addEventListener('click', event => {
            Global.showLoading ();
            this.processPayments();
        });

        this.buttonSync.addEventListener('click', event => {
            Global.showLoading ();
            this.syncPayments();
        });
        

        Global.hideLoading ();

    }


    async deleteBridgeUsers () {
        
        var deleteUsers =  Global.firebaseFunctions.httpsCallable('admin-deleteBridgeUsers');
        let result = await deleteUsers({ });
        Global.hideLoading ();
    }

    async syncPayments () {
        
        var syncTransfers =  Global.firebaseFunctions.httpsCallable('admin-syncTransfersBridge');
        let result = await syncTransfers({ });
        Global.hideLoading ();
        console.log (result.data.bridgeTransfers);
    }

    async processPayments () {
        var payBulk =  Global.firebaseFunctions.httpsCallable('admin-payBulkBridge');
        let result = await payBulk({ callback_url:Global.appUrl + "#/callback"});
        Global.hideLoading ();

        if (result.data.status == "no_payment") {
            swal ("Oups", "Pas de paiements à générer", "info" );
            return;
        }

        if (result.data.status == "error") {
            swal ("Oups", "Une erreur est survenue", "error" );
            return;
        }

        if (result.data.status == "success") {

            window.location.assign(result.data.redirect_url);
        }
    }

    async connectBank () {
        var connectBank =  Global.firebaseFunctions.httpsCallable('admin-connectBridgeItem');
        let result = await connectBank({ callback_url:Global.appUrl + "#/callback"});
        Global.hideLoading ();

        window.location.assign(result.data.redirect_url);
    }


    disconnectedCallback() {

    }

}

customElements.define('view-payment-list', ViewPaymentList);