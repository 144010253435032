/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";
import firebase from "firebase/app";

import html from 'bundle-text:./template.html';

export class ViewSurveyCreate extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        this.innerHTML = Mustache.render(template, {});
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-create");
        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-SurveyCreate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                name:this.querySelector("#input-name").value,
                formId:this.querySelector("#input-typeformUrl").value,
                gainType:this.querySelector("#input-gainType").options[this.querySelector("#input-gainType").selectedIndex].value,
                gainAnimation:this.querySelector("#input-gainAnimation").value,
                introVideo:this.querySelector("#input-introVideo").value,
                gainAmount:parseFloat(this.querySelector("#input-gainAmount").value),
                maxAnswers:parseFloat(this.querySelector("#input-maxAnswers").value),
                currentAnswers:parseFloat(this.querySelector("#input-currentAnswers").value),
                dateStart:firebase.firestore.Timestamp.fromMillis (this.querySelector("#input-dateStart").valueAsNumber),
                dateEnd:firebase.firestore.Timestamp.fromMillis (this.querySelector("#input-dateEnd").valueAsNumber),
                targetLocations:this.querySelector("#input-targetLocations").options[this.querySelector("#input-targetLocations").selectedIndex].value,
                targetPlaces:this.querySelector("#input-targetPlaces").options[this.querySelector("#input-targetPlaces").selectedIndex].value,
            }

        })

        window.location.assign(Global.appUrl + "#/admin/survey/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-survey-create', ViewSurveyCreate);