/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";
import firebase from "firebase/app";

import html from 'bundle-text:./template.html';

export class ViewSurveyEdit extends HTMLElement {

    constructor(options) {
        super();
        this.dataId = options.id;

    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        var getData =  Global.firebaseFunctions.httpsCallable('admin-SurveyGet');
        let result = await getData({ id : this.dataId })
        this.innerHTML = Mustache.render(template, result.data);
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-save");
        this.buttonDelete = this.querySelector("#button-delete");

        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });

        this.buttonDelete.addEventListener('click', event => {
            this.deleteData();
        });
    }

    async saveData () {
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-SurveyUpdate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                id : this.querySelector("#input-id").value,
                name:this.querySelector("#input-name").value,
                formId:this.querySelector("#input-typeformUrl").value,
                gainType:this.querySelector("#input-gainType").options[this.querySelector("#input-gainType").selectedIndex].value,
                gainAnimation:this.querySelector("#input-gainAnimation").value,
                introVideo:this.querySelector("#input-introVideo").value,
                gainAmount:parseFloat(this.querySelector("#input-gainAmount").value),
                maxAnswers:parseFloat(this.querySelector("#input-maxAnswers").value),
                currentAnswers:parseFloat(this.querySelector("#input-currentAnswers").value),
                dateStart: firebase.firestore.Timestamp.fromMillis (this.querySelector("#input-dateStart").valueAsNumber),
                dateEnd: firebase.firestore.Timestamp.fromMillis (this.querySelector("#input-dateEnd").valueAsNumber),
                targetLocations:this.querySelector("#input-targetLocations").options[this.querySelector("#input-targetLocations").selectedIndex].value,
                targetPlaces:this.querySelector("#input-targetPlaces").options[this.querySelector("#input-targetPlaces").selectedIndex].value,
            }

        })

        window.location.assign(Global.appUrl + "#/admin/survey/list");
        
    }

    async deleteData () {
        Global.showLoading ();
        var deleteData =  Global.firebaseFunctions.httpsCallable('admin-SurveyDelete');
        let result = await deleteData({ 
            id : this.dataId
        })

        window.location.assign(Global.appUrl + "#/admin/survey/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-survey-edit', ViewSurveyEdit);