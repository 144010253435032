/**
 * View Create
 *
 * @description :: Create view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';

export class ViewPaymentCreate extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
        
    }

    async initView(template) {
        this.innerHTML = Mustache.render(template, {});
        Global.hideLoading ();

        this.buttonSave = this.querySelector("#button-create");
        this.buttonSave.addEventListener('click', event => {
            this.saveData();
        });
    }

    async saveData () {
        console.log ("Save data");
        Global.showLoading ();
        var saveData =  Global.firebaseFunctions.httpsCallable('admin-PaymentCreate');
        let result = await saveData({ 
            id : this.dataId,
            data : {
                userId:this.querySelector("#input-userId").value,                
                locationId:this.querySelector("#input-locationId").value, 
                surveyId:this.querySelector("#input-surveyId").value, 
                answerId:this.querySelector("#input-answerId").value, 
                status:this.querySelector("#input-status").value, 
                amount:parseFloat(this.querySelector("#input-amount").value), 
                iban:this.querySelector("#input-iban").value, 
                datePaid:this.querySelector("#input-datePaid").value, 
                dateCreated:this.querySelector("#input-dateCreated").value, 
                paid:this.querySelector("#input-paid").value
            }

        })

        window.location.assign(Global.appUrl + "#/admin/payment/list");
        
    }


    disconnectedCallback() {

    }

}

customElements.define('view-payment-create', ViewPaymentCreate);