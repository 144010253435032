/**
 * View Login
 *
 * @description :: View used to login to the web app via API
 */
import Mustache from "mustache";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebaseui";

import html from 'bundle-text:./template.html';
import style from 'bundle-text:./template.css';

export class ViewLogin extends HTMLElement {

    constructor(options) {
        super();
        this.requestedUrl = options.requestedUrl;
        let styleSheet = document.createElement("style");
        styleSheet.textContent = style;

        this.appendChild(styleSheet);

    }

    connectedCallback() {
        this.initView(html);
    }

    initView(template) {
        this.innerHTML = Mustache.render(template, {});
        Global.hideLoading ();

        this.buttonLogin = this.querySelector("#button-login");
        this.buttonReset = this.querySelector("#button-reset");

        this.inputEmail = this.querySelector("#input-email");
        this.inputPassword = this.querySelector("#input-password");
        this.buttonLogin.addEventListener('click', event => {
            this.loginUser();
        });
        this.buttonReset.addEventListener('click', event => {
            this.resetPassword();
        });

    }

    loginUser() {
        var email = this.inputEmail.value;
        var password = this.inputPassword.value;
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;

          window.location.assign(this.requestedUrl);
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            swal("Oups !",errorMessage, "error");

        });

    }

    resetPassword () {
        var email = this.inputEmail.value;
        Global.firebaseAuth.sendPasswordResetEmail(email)
        .then(() => {
            swal("Lien envoyé.");

        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            swal("Oups !",errorMessage, "error");
        });
    }

    disconnectedCallback() {

    }

}

customElements.define('view-login', ViewLogin);