/**
 * View Default
 *
 * @description :: Default view template
 */
import Mustache from "mustache";


import html from 'bundle-text:./template.html';
import { Collection } from "../../models/collection.js";

export class ViewCallback extends HTMLElement {

    constructor(options) {
        super();
    }

    connectedCallback() {
        this.initView(html);
    }

    async initView(template) {
        const queryString = window.location.href;
        const urlParams = new URLSearchParams(queryString);
        const success = urlParams.get('success'); // true/false
        const source = urlParams.get('source'); // connect
        const step = urlParams.get('step'); // sync_success
        const user_uuid = urlParams.get('user_uuid'); // userid
        const item_id = urlParams.get('item_id'); // 1234567
        const context=  urlParams.get('context')//adminPayBulk
        console.log (context);
        switch (context) {
            case 'adminPayBulk':
                // https://app.satisfyy.fr/#/callback/?source=pay&success=true&user_uuid=b5d6ba7e-8e4b-4274-91db-c9c9e2f9df67&step=transfer_success&bulk_id=f7e04e4c-26d2-4bbf-aeaa-6792fd35d9d1&context=adminPayBulk
                const bulk_id = urlParams.get('bulk_id'); // 1234567
                if ((step == "transfer_success")&&(success==true)){
                    swal ("Merci !", "Paiement effectué !","success");
                    var payBulkSuccess =  Global.firebaseFunctions.httpsCallable('admin-payBulkSuccess');
                    let result = await payBulkSuccess({ });
                } else {
                    swal ("Oups !", "Virement non effectué !","error");
                    var payBulkFailed =  Global.firebaseFunctions.httpsCallable('admin-payBulkFailed');
                    let result = await payBulkFailed({ });
                }
                window.location.assign(Global.appUrl + "#/admin/payment/list");
                return;
              break;

              case 'connectAdminItem':
                swal ("Merci !", "Banque connectée !","success");
                window.location.assign(Global.appUrl + "#/admin/payment/list");
                return;
              break;   

              case 'connectUserItem':
              
              break;   
          }
          
        var getBridgeItem =  Global.firebaseFunctions.httpsCallable('getBridgeItem');
        let result = await getBridgeItem({ });

        this.innerHTML = Mustache.render(template, {data:result.data});
        Global.hideLoading ();

        var buttonsIban = this.querySelectorAll(".button-iban");
        buttonsIban.forEach(el => el.addEventListener('click', async event => {
            Global.showLoading ();

            let iban = event.target.getAttribute("data-iban");
            var userUpdate =  Global.firebaseFunctions.httpsCallable('userUpdate');
            let result = await userUpdate({data:{ iban:iban}});
            Global.hideLoading ();

            swal ("Merci !", "Votre compte a bien été ajouté","success");
            window.location.assign(Global.appUrl + "#/account");

        }));
          

    }

 

    disconnectedCallback() {

    }

}

customElements.define('view-callback', ViewCallback);